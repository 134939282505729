import { CheckoutStep } from '@finn/ua-auth';
import { IncomingHttpHeaders } from 'http';

import { VoucherClass, VoucherType } from '~/types/voucher';

import { ID_DOCUMENT_TYPES } from '../CustomerProduct/services/customerSelfService';
import { VehicleOfferType } from './finance';
import { CountryCode, FinancingType, FinancingTypeUS } from './localization';

export type Stage = 'contact' | 'payment' | 'confirmation';
export type NextStage = Stage | 'thank_you' | 'financial' | 'prolongation';

export enum CheckoutVisualStep {
  CONTACT = 'contact',
  ADDRESS = 'address',
  DELIVERY = 'delivery',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
}

export type UrlFilter = {
  [x: string]: string[];
};

export type CartDealInfo = {
  email?: string;
  vehicleId?: string;
  vin?: string;
  term?: number;
  deliveryDate?: string;
  deliveryDate_iso?: string;
  kilometerPackage?: number;
  tax?: number;
  excise_tax?: number;
  deductibleAmount?: number;
};

export type CartInfo = CartDealInfo & {
  isBlacklisted: boolean;
  monthlyPrice: number;
  originalAmount: number;
  dealSource: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleColor: string;
  vehicleCartype: string;
  deliveryFee?: number;
  offerType?: VehicleOfferType;
  financingType: FinancingType | FinancingTypeUS;
  countryCode: CountryCode;
  customerSpecificVkDamageDeductible?: string;
  downPaymentAmount?: number;
  downPaymentDiscount?: number;
  voucherAmount?: number;
  voucherClass?: VoucherClass;
  voucherType?: VoucherType;
};

export type PreviousDealRequestPayload = {
  contactId: string;
  isUSA?: boolean;
};

type CommonCartInfo = {
  kmPackage: number;
  term: number | string;
  vehicleId: string;
  vehicleImage: string;
  amount: number;
  brand: string;
  model: string;
};

export type PreviousDealInfo = CommonCartInfo & {
  dealId: number | string;
  isVehicleAvailable: boolean;
  message: string;
  contactId: number | string;
  checkoutHash: string;
  checkoutStep: string;
};

export type PreviousCartInfo = CommonCartInfo & {
  locale: string;
  isCart?: boolean;
  isPDP?: boolean;
  isConfigure?: boolean;
  downPaymentAmount?: number;
};

type AuthorizedSignatory = {
  salutation: string | null;
  birthday: string | null;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
  email: string | null;
  street: string | null;
  housenumber: string | null;
  zipcode: string | null;
  city: string | null;
};
type DeliveryAddress = {
  firstname: string | null;
  lastname: string | null;
  street: string | null;
  housenumber: string | null;
  zipcode: string | null;
  city: string | null;
  extra: string | null;
  state: string | null;
};
type FinancialInfoB2C = {
  revenue: string | null;
  businessYear: string | null;
  incomeProofImage: string | null;
  tradeRegisterProofImage: string | null;
  companyRegistrationProofImage: string | null;
};
type FinancialInfoB2B = {
  employmentType: EmploymentType | null;
  netIncome: string | null;
  incomeProofImage: string | null;
  startedJobMonth: number | null;
  startedJobYear: string | null;
  companyRegistrationProofImage: string | null;
};
export type ContactInfo = {
  email: string | null;
  emailSubscription: boolean | null;
  dealId: number;
  contactId: number;
  hash: string;
  type: ClientType.BUSINESS | ClientType.PRIVATE;
  firstname: string | null;
  lastname: string | null;
  birthday?: Date | string | null;
  company?: string | null;
  organizationType?: string | null;
  foundationYear?: number | null;
  street: string | null;
  housenumber: string | null;
  zipcode: string | null;
  city: string | null;
  phone: string | null;
  state: string | null;
  salutation?: string;
  nationality?: string;
  deliveryDate?: Date | string | null;
  deliveryAddress?: DeliveryAddress;
  authorizedSignatory?: AuthorizedSignatory;
  hasDeliveryAddress?: boolean;
  schufaScore: number | string;
  schufaScoreRange: string;
  ficoScore: number | string;
  ofacSearchResult: string;
  fleetSize?: number;
  extra: string;
};

export type ContactHubspotInfo = {
  id: string | null;
  email: string | null;
  contactId: number;
  firstname: string | null;
  lastname: string | null;
  birthday?: Date | string | null;
  company?: string | null;
  phone?: string | null;
  street?: string | null;
  housenumber?: string | null;
  zipcode?: string | null;
  city?: string | null;
};

export type ComingSoonDealCreationBody = {
  vehicleId: string;
  term: number;
  email: string;
  type: ClientType.COMING_SOON | ClientType.BACK_IN_STOCK;
  firstname: string;
  lastname: string;
};

export type DirectCheckoutEligibilityBody = {
  vehicleId: string;
  zipcode?: string;
};
export type DirectCheckoutEligibilityResult = {
  checkoutStep: string;
  allowDirectCheckout: boolean;
};

export type DirectCheckoutDealCreationBody = {
  vehicleId: string;
  term?: number;
  kilometerPackage?: number;
  zipcode?: string;
  downpaymentSelected?: boolean;
};

export type DirectCheckoutDealCreationResult = {
  contact: ContactHubspotInfo;
  hash: string;
  checkoutStep: string;
  deal: { id: number };
  creditScore: number;
};

export type LocationUnavailableDealCreationBody = {
  vehicleId: string;
  zipcode: string;
  email: string;
  type: ClientType.PRIVATE;
  firstname: string;
  lastname: string;
  state: string;
  term: number;
};

export type LeadCreationBody = {
  vehicleId: string;
  term: number;
  kilometerPackage: number;
  email: string;
  firstname: string;
  lastname: string;
  birthday: string;
  phone: string;
  downpaymentSelected?: boolean;
};

export type ContactUpdateBody = {
  email: string;
  dealId?: number;
  contactId?: number;
  hash?: string;
  firstname: string;
  lastname: string;
  birthday: string;
  phone: string;
};

export type DealCreationBody = {
  vehicleId: string;
  term: number;
  kilometerPackage: number;
  email: string;
  emailSubscription: boolean;
  dealId?: number;
  contactId?: number;
  hash?: string;
  type: ClientType.BUSINESS | ClientType.PRIVATE;
  firstname: string;
  lastname: string;
  birthday: string;
  company?: string;
  street: string;
  housenumber: string;
  zipcode: string;
  city: string;
  phone: string;
  deliveryAddress: DeliveryAddress;
  isDirectCheckoutEnabled?: boolean;
};

export type DealCreationWithAuth = {
  vehicleId: string;
  term: number;
  kilometerPackage: number;
  type: ClientType.BUSINESS | ClientType.PRIVATE;
};

export type ProcessPaymentInfo = {
  contactId?: number | string;
  dealId?: number | string;
  hash?: string;
  recurrentPaymentMethod?: string;
  confirmedSetupIntent?: string;
  additionalPaymentMethod?: string;
};

export type PaymentInfo = {
  contactId: number;
  dealId: number;
  hash: string;
  recurrentPaymentMethod?: string;
  singlePaymentMethod?: string;
  stripeIntentId?: string;
  stripeIntentClientSecret?: string;
  stripeSourceId?: string;
  stripeSourceClientSecret?: string;
  accountHolder?: string;
  maskedIBAN: string; // We need it for D2C
  IBAN: string; // We need it for D2C
  isApplicableForJobAuto?: boolean;
  deposit: number;
  depositFactor?: number | string;
  stripeDetails?: {
    errorContent?: {
      code: string;
      message: string;
      type: string;
    };
    status?: string;
  };
  paymentMethod: {
    card: {
      cardHolder: string;
      cardBrand: string;
      country: string;
      expMonth: number;
      expYear: number;
      name: string;
      last4: string;
    };
    sepa_debit: {
      country: string;
      last4: string;
    };
    us_bank_account: {
      accountHolder?: string;
      account_holder_type?: string;
      account_type?: string;
      bank_name?: string;
      last4?: string;
      routing_number?: string;
      status?: string;
    };
  };
  jobauto_id?: string;
  jobauto_domain?: string;
  jobauto_address?: string;
  jobauto_name?: string;
  directCheckout?: string;
  skippedPaymentMethod?: string;
  stripeIntentStatus?: string;
};

export type DealInfo = {
  contactId?: number | string;
  dealId?: number | string;
  hash?: string;
  isDirectCheckout?: boolean;
};
export type IntentCreationInfo = {
  contactId: number | string;
  dealId: number | string;
  hash: string;
  recurrentPaymentMethod?: string;
  forceNewIntent?: boolean;
};

export type ConfirmationPayload = DealInfo & {
  amount: number;
  deliveryDate: string;
  swapableDealId?: number;
};

export type UpdateDealInfo = DealInfo & {
  term: number;
  kilometerPackage: number;
};

export type ThankYouInfo = {
  licenceApproved: boolean;
  creditCheck: boolean;
  confirmation: boolean;
  isPurchaseEventTriggered: boolean;
  matchedCarId?: string;
  didUserRegister: boolean;
  didUserVerifyEmail: boolean;
  verificationStatus: string;
  driverLicense: {
    frontImage: string;
    backImage: string;
  };
  hasPaid?: boolean;
  contractSigning?: boolean;
  contractCreation?: boolean;
  externalVerificationStatuses?: {
    externalVerificationDecisionDl?: string;
    externalVerificationDecisionId?: string;
    externalVerificationStatusDl?: string;
    externalVerificationStatusId?: string;
    externalVerificationStatusProofOfAddress?: {
      value: string;
    };
    externalVerificationDecisionProofOfAddress?: {
      value: string;
    };
    idVerificationDocumentType?: {
      value: ID_DOCUMENT_TYPES;
    };
  };
  internalVerificationStatuses?: {
    internalVerificationDecisionDl?: string;
    internalVerificationDecisionId?: string;
  };
};

export enum ThankYouStep {
  USER_REGISTRATION,
  EMAIL_VERIFICATION,
  DL_VERIFICATION,
  ID_VERIFICATION,
  POA_VERIFICATION,
}

export type ProlongationInfo = {
  prolongationStartDate?: string;
  prolongationEndDate?: string;
  prolongationPrice?: number;
  prolongationTaxValue?: number;
  prolongationVoucherAmount?: number;
  prolongationTerm?: number;
  prolongationMileage?: number;
  customerSpecificVkDamageDeductible?: number;
  customerSpecificTkDamageDeductible?: number;
};

export type ConfirmationInfo = {
  deliveryFee: number;
  matchedCarId: string;
  deposit: number;
  depositFactor?: number | string;
  discount: number;
  amount: number;
  mileagePackageFee: number;
  additionalMileageFee: number;
  voucherCode?: string;
  voucherClass?: string;
  voucherId?: string;
  voucherAmount?: number;
  voucherType?: string;
  deliveryDate: string;
  deliveryDate_iso?: string;
  contractInceptionTimestamp?: number;
  contractEndDateTimestamp?: number;
  contractEndDate_iso?: string;
  signedTimestamp?: number;
  licensePlate?: string;
  jobAutoCompanyDomain?: string;
  belongsToSubscriptionService?: string | boolean;
  subscriptionInfo?: SubscriptionInfo;
  customerSpecificVkDamageDeductible?: number;
  customerSpecificTkDamageDeductible?: number;
};

export enum ClientType {
  PRIVATE = 'B2C',
  BUSINESS = 'miniB2B',
  COMING_SOON = 'comingsoon',
  BACK_IN_STOCK = 'backinstock',
}

export enum OrganizationType {
  KEIN_HANDELSREGISTEREINTRAG = 'kein Handelsregistereintrag',
  AKTIENGESELLSCHAFT = 'Aktiengesellschaft',
  BGB_GESELLSCHAFT = 'BGB-Gesellschaft (GBR)',
  EINGETRAGENER_EINZELFIRMA = 'eingetr. Einzelfirma',
  EINGETRAGENER_VEREIN = 'eingetragener Verein',
  GENOSSENSCHAFT = 'Genossenschaft',
  GMBH = 'GmbH',
  GMBH_COKG = 'GmbH & Co.KG',
  KG = 'KG',
  STIFTUNG = 'Stiftung',
}

export enum EmploymentType {
  EMPLOYED = 'Angestellt',
  SELF_EMPLOYED = 'Selbstständig',
  RETIREMENT = 'In Rente',
}

export { ContractTermType, FinancingType } from './finance';

export type LicenseInfo = {
  contactId: string;
  dealId: string;
  hash: string;
  frontImage: string;
  backImage: string;
};

export type D2CJson = {
  content: string;
  filename: string;
  contactId: string;
  dealId: string;
  hash: string;
};

export type ProfessionInfo = {
  street: string;
  housenumber: string;
  zipcode: string;
  city: string;
  residenceMonth: number;
  residenceYear: number;
  livingType: string;
  rentingCost: number;
  maritalStatus: string;
};

export type PreviousAddressInfo = {
  street: string;
  housenumber: string;
  zipcode: string;
  city: string;
};

export type FinancialInfo = {
  contactInfo: ContactInfo;
  professionInfo: ProfessionInfo;
  financialInfo: FinancialInfoB2C | FinancialInfoB2B;
  previousAddressInfo: PreviousAddressInfo;
  fileList: Array<string>;
  matchedCarId?: string;
};

export enum Nationality {
  OTHER = 'other',
}

export type CheckoutServerSidePropsPayload = {
  checkoutStep: string;
  cartInfo: CartInfo;
  contactInfo: ContactInfo;
  paymentInfo: PaymentInfo;
  financialInfo: FinancialInfo;
  confirmationInfo: ConfirmationInfo;
  thankYouInfo: ThankYouInfo;
  prolongationInfo: ProlongationInfo;
  locale: string;
  dealInfo: DealInfo;
  requestHeaders: IncomingHttpHeaders;
};

export type RestoredData = {
  cart: CartInfo;
  checkoutStep: CheckoutStep;
  confirmation: ConfirmationInfo;
  contact: ContactInfo;
  prolongation: ProlongationInfo;
  isCreditScoreValid: boolean;
  isDealClosed: {
    value: boolean;
    reason?: string;
  };
  payment: PaymentInfo;
  data?: {
    message?: string;
  };
};

export type SubscriptionInfo = {
  config_id: string;
  mileage_package: number;
  term: number;
  is_active_sub_agreement: string;
  vin: string;
  year: string;
  color: string;
  deal_id: string;
  sales_tax: number;
  deal_stage: string;
  base_amount: number;
  finn_car_id: string;
  financing_type: FinancingType | FinancingTypeUS;
  depreciation: number;
  license_plate: string;
  residual_value: number;
  excise_property_tax: number;
  mileage_package_fee: string;
  gross_capitalized_cost: number;
  subscription_agreement_url: string;
  contract_end_date: string;
  contract_start_date: string;
  needs_authoritative_copy_process: boolean;
};

export type EditOrderResponseData = {
  status?: number;
  contact: { id: number };
  deal: { id: number };
  checkoutStep: string;
  term: number;
  kilometerPackage: number;
  mileagePackageFee: number;
  monthlyPrice: number;
  additionalMileageFee: number;
  amountAfterVoucher: number;
};
